import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/css/core';
import { hasBodyClass } from './js/helpers';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";


document.addEventListener('DOMContentLoaded', function () {

  // General
  Fancybox.bind('[data-fancybox]');

  // Site header
  const siteHeader = document.querySelector('.rtnu-header');
  if (siteHeader) {
    const toggleIcon = siteHeader.querySelector('.rtnu-header__toggle');
    toggleIcon.addEventListener('click', function () {
      this.classList.toggle('is-active');
      siteHeader.classList.toggle('mobile-menu-open');
    });
  }

  // PDP
  if (hasBodyClass('product-')) {
    let pdpMainCarousel = new Splide('#pdp-main-carousel', {
      perPage: 1,
      perMove: 1,
      arrows: false,
      pagination: false,
      destroy: true,
      breakpoints: {
        768: {
          destroy: false,
          fixedHeight: 320,
          padding: '20px',
          variableWidth: true,
        },
      },
    });

    let pdpMainCarouselBarContainer = pdpMainCarousel.root.querySelector('.rtnu-product__carousel-progress');
    let pdpMainCarouselBar = pdpMainCarousel.root.querySelector('.rtnu-product__carousel-progress-bar');

    pdpMainCarousel.on('move', function(newIndex) {
      // console.log("Slider moved to index:", newIndex);

      if(newIndex === 0) {
        // console.log("Resetting progress bar position to 0px.");
        pdpMainCarouselBar.style.transform = 'translateX(0px)';
      } else {
        let end = pdpMainCarousel.Components.Controller.getEnd() + 1;
        let rate = Math.min((pdpMainCarousel.index + 1) / end, 1);
        let maxTranslate = pdpMainCarouselBarContainer.offsetWidth - pdpMainCarouselBar.offsetWidth;
        let translateValue = maxTranslate * rate;

        // console.log("Setting progress bar position to:", translateValue + "px");
        pdpMainCarouselBar.style.transform = 'translateX(' + translateValue + 'px)';
      }
    });

    pdpMainCarousel.mount();
  }

  // accordion
  const accordionItems = document.querySelectorAll(".rtnu__accordion-item");

  function toggleAccordionItem(event) {
    const accordionItemHeader = event.target.closest(
      ".rtnu__accordion-item-toggle"
    );
    if (!accordionItemHeader) return;

    const accordionItem = accordionItemHeader.parentNode;
    const accordionItemContent = accordionItem.querySelector(
      ".rtnu__accordion-item-content"
    );
    if (!accordionItemContent) return;

    const isOpen = accordionItem.classList.toggle("is-open");
    const maxHeight = isOpen ? accordionItemContent.scrollHeight + "px" : "0";
    accordionItemContent.style.maxHeight = maxHeight;
    accordionItemHeader.setAttribute("aria-expanded", isOpen);
  }

  accordionItems.forEach((item) => {
    const header = item.querySelector(".rtnu__accordion-item-toggle");
    header.addEventListener("click", toggleAccordionItem);
    header.addEventListener("keydown", function (event) {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        toggleAccordionItem(event);
      }
    });
  });

  // Customer Media Highlights
  const mediaHighlightsSlider = document.querySelectorAll('.rtnu-customer-media-highlights__carousel');
  if(mediaHighlightsSlider) {
    mediaHighlightsSlider.forEach(slider => {
      new Splide(slider, {
        perMove: 1,
        arrows: false,
        pagination: false,
        fixedWidth: 282,
        gap: '24px',
        breakpoints: {
          768: {
            fixedWidth: 220,
            gap: '16px',
          }
        },
      }).mount();
    });
  }

    // Slider Columns
    const columnsSlider = document.querySelectorAll('.rtnu-slider-columns__carousel');
    if(columnsSlider) {
      columnsSlider.forEach(slider => {
        new Splide(slider, {
          perMove: 1,
          arrows: false,
          pagination: true,
          fixedWidth: 282,
          gap: '24px',
          breakpoints: {
            768: {
              gap: '16px',
            }
          },
        }).mount();
      });
    }

  // auto scrolling bar
  const marquee = document.querySelectorAll('.rtnu-marquee');
  if(marquee) {
    marquee.forEach(el => {
      new Splide(el, {
        type   : 'loop',
        drag   : 'free',
        focus  : 'center',
        arrows: false,
        pagination: false,
        perPage: 3,
        gap: '32px',
        autoScroll: {
          speed: 1,
        },
      }).mount({ AutoScroll });
    });
  }

  // Customer quotes
  const customerQuotesSlider = document.querySelectorAll('.rtnu-customer-quotes__carousel');
  if(customerQuotesSlider) {
    customerQuotesSlider.forEach(slider => {
      new Splide(slider, {
        perMove: 1,
        arrows: false,
        pagination: true,
        fixedWidth: 282,
        gap: 'clamp(2rem, -7.333rem + 19.444vw, 6rem)',
        breakpoints: {
          768: {
            gap: '8px',
          }
        },
      }).mount();
    });
  }

  // Products Block Slider
  let productsBlockSliderElement = document.querySelector(".rtnu-products-block__slider");
  if (productsBlockSliderElement){
    let productsBlockSlider = new Splide( '.rtnu-products-block__slider', {
      perMove: 1,
      destroy: true,
      arrows: false,
      pagination: false,
      breakpoints: {
        768: {
          fixedWidth: '268px',
          destroy: false,
          gap: "24px",
          padding: "20px",
          dragMinThreshold: 20,
        }
      }
    });

    let productsBlockSliderBar = productsBlockSlider.root.querySelector( '.rtnu-products-block__slider-progress-bar' );

    // Updates the bar width whenever the carousel moves:
    productsBlockSlider.on( 'mounted move', function () {
      let end = productsBlockSlider.Components.Controller.getEnd() + 1;
      let rate = Math.min( ( productsBlockSlider.index + 1 ) / end, 1 );
      productsBlockSliderBar.style.width = String( 100 * rate ) + '%';
    } );

    productsBlockSlider.mount();
  }

  // Articles Block Slider
  const articlesBlockSlider = document.querySelectorAll('.rtnu-articles-block__carousel');
  if(articlesBlockSlider) {
    articlesBlockSlider.forEach(slider => {
      new Splide(slider, {
        perMove: 1,
        arrows: false,
        pagination: true,
        fixedWidth: 282,
        gap: '24px',
      }).mount();
    });
  }

  // Cart Page
  // This button will increment the value
  document.querySelectorAll('.qtyplus').forEach(function(button) {
    button.addEventListener('click', function(e) {
        // Stop acting like a button
        e.preventDefault();

        // Get the field name
        var fieldName = e.target.getAttribute('field');

        // Get its current value
        var inputElement = document.querySelector('input[id="' + fieldName + '"]');
        var currentVal = parseInt(inputElement.value, 10);

        // If is not undefined
        if (!isNaN(currentVal)) {
            // Increment
            inputElement.value = currentVal + 1;
        } else {
            // Otherwise put a 0 there
            inputElement.value = 0;
        }
    });
  });

  // This button will decrement the value till 0
  document.querySelectorAll('.qtyminus').forEach(function(button) {
    button.addEventListener('click', function(e) {
        // Stop acting like a button
        e.preventDefault();

        // Get the field name
        var fieldName = e.target.getAttribute('field');

        // Get its current value
        var inputElement = document.querySelector('input[id="' + fieldName + '"]');
        var currentVal = parseInt(inputElement.value, 10);

        // If it isn't undefined or its greater than 0
        if (!isNaN(currentVal) && currentVal > 1) {
            // Decrement one
            inputElement.value = currentVal - 1;
        } else {
            // Otherwise put a 1 there
            inputElement.value = 1;
        }
    });
  });
});
